import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import '../styles/common.sass'
import '../styles/reset.sass'
import HeroTools from '../sections/heroTools/HeroTools'
import BadgesTools from '../sections/badgesTools/BadgesTools'
import OurTools from '../sections/ourTools/OurTools'
const ToolsPage = () => {
  return (
    <Layout>
      <Seo title="$GODLENFISH" />
      <div className="section">
        <HeroTools />
        <BadgesTools />
        <OurTools />
      </div>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export const Head = () => <Seo title="Majestic and will lead the way in the crypto sea" />

export default ToolsPage
