import React from 'react'
import './ourTools.sass'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const OurTools = () => {
  const tools = [{ name: 'GODLENsend', description: 'Send tokens to hundreds of wallets with just a few clicks—multisender that simplifies bulk transactions.', image: '../../images/tools/tool1.png' },
    { name: 'GODLENtapper', description: 'Earn free tokens by playing telegram tapper game, or create own tailored to your community needs.', image: '../../images/tools/tool2.png' }]

  return (
  <div id="ourTools" className="ourToolsSection">
    <h2>Our tools</h2>
    <div className="ourToolsInnerWrap">
      <div className="ourToolsRow">
        <div className="toolImageWrap">
          <h3>GODLENsend</h3>
          <p>Send tokens to hundreds of wallets with just a few clicks—multisender that simplifies bulk transactions.</p>
          <StaticImage
              src="../../images/tools/tool1.png"
              className="ourToolsImage"
              placeholder="blurred"
              layout="constrained"
              alt="The Godlenfish ourTools"
            />
        </div>
        <a href="https://godlenpaper.godlenfish.com/godlenpaper/usdgodlen-utility" target="_blank" rel="noreferrer">Read more</a>
      </div>
      <div className="ourToolsRow">
        <div className="toolImageWrap">
          <h3>GODLENtapper</h3>
          <p>Earn free token's by playing telegram tapper game, or create own tailored to your community needs.</p>
          <StaticImage
              src="../../images/tools/tool2.png"
              className="ourToolsImage"
              placeholder="blurred"
              layout="constrained"
              alt="The Godlenfish ourTools"
            />
        </div>
        <a href="https://godlenpaper.godlenfish.com/godlenpaper/usdgodlen-utility" target="_blank" rel="noreferrer">Read more</a>
      </div>
      <div className="ourToolsRow">
        <div className="toolImageWrap">
          <h3>GODLENlauncher</h3>
          <p>Effortlessly create your Solana SPL or other chain token with our simple process—no coding required.</p>
          <StaticImage
              src="../../images/tools/tool3.png"
              className="ourToolsImage"
              placeholder="blurred"
              layout="constrained"
              alt="The Godlenfish ourTools"
            />
        </div>
        <a href="https://godlenpaper.godlenfish.com/godlenpaper/usdgodlen-utility" target="_blank" rel="noreferrer">Read more</a>
      </div>
      <div className="ourToolsRow">
        <div className="toolImageWrap">
          <h3>GODLENtracker</h3>
          <p>Telegram bot that announces all incoming transactions to specified SOL wallet or Smart Contract.</p>
          <StaticImage
              src="../../images/tools/tool4.png"
              className="ourToolsImage"
              placeholder="blurred"
              layout="constrained"
              alt="The Godlenfish ourTools"
            />
        </div>
        <a href="https://godlenpaper.godlenfish.com/godlenpaper/usdgodlen-utility" target="_blank" rel="noreferrer">Read more</a>
      </div>
      <div className="ourToolsRow">
        <div className="toolImageWrap">
          <h3>GODLENscan</h3>
          <p>Simple on-chain data analytics tool that can export all details of any SOL wallet or SOL contract, including token holders, transactions and more...</p>
          <StaticImage
              src="../../images/tools/tool5.png"
              className="ourToolsImage"
              placeholder="blurred"
              layout="constrained"
              alt="The Godlenfish ourTools"
            />
        </div>
        <a href="https://godlenpaper.godlenfish.com/godlenpaper/usdgodlen-utility" target="_blank" rel="noreferrer">Read more</a>
      </div>
      <div className="ourToolsRow">
        <div className="toolImageWrap">
          <h3>GODLENsniper</h3>
          <p>Sniper bot that makes profit easy—just provide a contract address and investment details, and we handle the rest.</p>
          <StaticImage
              src="../../images/tools/tool6.png"
              className="ourToolsImage"
              placeholder="blurred"
              layout="constrained"
              alt="The Godlenfish ourTools"
            />
        </div>
        <a href="https://godlenpaper.godlenfish.com/godlenpaper/usdgodlen-utility" target="_blank" rel="noreferrer">Read more</a>
      </div>
      <div className="ourToolsRow">
        <div className="toolImageWrap">
          <h3>GODLENstake</h3>
          <p>Earn rewards by staking tokens, or create your own customisable staking pool in seconds.</p>
          <StaticImage
              src="../../images/tools/tool7.png"
              className="ourToolsImage"
              placeholder="blurred"
              layout="constrained"
              alt="The Godlenfish ourTools"
            />
        </div>
        <a href="https://godlenpaper.godlenfish.com/godlenpaper/usdgodlen-utility" target="_blank" rel="noreferrer">Read more</a>
      </div>
      <div className="ourToolsRow">
        <div className="toolImageWrap">
          <h3></h3>
          <p></p>
          <StaticImage
              src="../../images/tools/tool8.png"
              className="ourToolsImage"
              placeholder="blurred"
              layout="constrained"
              alt="The Godlenfish ourTools"
            />
        </div>
        <a href="https://godlenpaper.godlenfish.com/godlenpaper/usdgodlen-utility" target="_blank" rel="noreferrer">To be revealed</a>
      </div>
    </div>
  </div>
  )
}

export default OurTools
