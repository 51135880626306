import React from 'react'
import './heroTools.sass'
import { StaticImage } from 'gatsby-plugin-image'

const heroTools = () => {
  return (
    <div className="heroToolsSection">
        <StaticImage
          src="../../images/big-godlen-fish.png"
          height={600}
          style={{ marginBottom: '-100px' }}
          className="heroToolsFish1"
          placeholder="blurred"
          layout="constrained"
          alt="The Godlenfish logo"
        />
        <div className='heroToolsCenter'>
          <h1>THE ULTIMATE GODLEN UTILITY DApp FOR SOLANA DEGENERATES</h1>
          <p>Get involved in crypto like top degen without knowing any basics. Hold GODLEN and utilise our DApp tools such as - sniper bots, token launchers, trackers, chain scanners and more...</p>
          <a href="#ourTools">Get started now</a>
        </div>
        <StaticImage
          src="../../images/godlenfish-coat.png"
          height={500}
          className="heroToolsFish2"
          placeholder="blurred"
          layout="constrained"
          alt="The Godlenfish logo"
        />
    </div>
  )
}

export default heroTools
